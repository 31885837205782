import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  DialogActions,
  Divider,
  FormControl,
  Grid2,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { MenuItemStyled } from "../../../components/MenuItemStyled";
import DialogHeader from "../../../components/dialog/DialogHeader";
import { useNotificationContext } from "../../../contexts/NotificationContext";
import { useLocalization } from "../../../hooks/useLocalization";
import { ReportGroup } from "../../api/biClient.types";
import { useSharedReportingLocalization } from "../../hooks/useLocalization";
import AddGroupDialog from "../reportGroups/AddGroupDialog";
import { FromReport } from "./FromReport";
import validateForm from "./utils/validateForm";

export interface Props {
  report: FromReport;
  groups?: ReportGroup[];
  title?: string;
  saveBtnTitle?: string;
  showName?: boolean;
  showLevel?: boolean;
  error?: string;
  saving: boolean;
  onClose: () => void;
  onSave: (name: string, groupId: string) => void;
}

export default function SaveReportForm(props: Props) {
  const { report, groups, title, saveBtnTitle, error, saving, showLevel, showName, onClose, onSave } = props;
  const { common: locale } = useLocalization();
  const { report_groups } = useSharedReportingLocalization();
  const { sendNotification } = useNotificationContext();

  const [name, setName] = useState(report.name);
  const [showAddLevel, setShowAddLevel] = useState(false);

  const [groupId, setGroupId] = useState(report.groupId || (groups && groups[0]?.id) || "");
  const isNameValid = name.trim().length >= 2;

  const isFormValid = validateForm(name.trim(), groupId, showName, showLevel);
  const handleSave = () => onSave(name.trim(), groupId);

  return (
    <>
      <DialogHeader text={title || ""} onClose={onClose} />
      {error && (
        <Alert sx={{ mb: 2, mx: 2.5 }} severity="error">
          {error}
        </Alert>
      )}
      <Grid2
        container
        sx={{ px: 2.5, py: 1, gap: 2, flex: 1, flexDirection: "column", justifyContent: "center", width: "100%" }}
      >
        {showName && (
          <TextField
            fullWidth
            value={name}
            onChange={(evt) => setName(evt.target.value)}
            error={!isNameValid}
            helperText={!isNameValid ? locale.validation_min_length : ""}
            label="Name"
            disabled={saving}
          />
        )}
        {showLevel && (
          <FormControl fullWidth disabled={saving}>
            <InputLabel>Group</InputLabel>
            <Select
              itemID="id"
              value={groupId}
              title={"caption"}
              label="Group"
              onChange={(evt) => setGroupId(evt.target.value as string)}
            >
              {groups?.map((rg) => {
                return (
                  <MenuItemStyled key={rg.id} value={rg.id}>
                    <Typography>{rg.caption}</Typography>
                  </MenuItemStyled>
                );
              })}
              <Divider />
              <MenuItemStyled sx={{ gap: 1 }} onClick={() => setShowAddLevel(true)}>
                <AddRoundedIcon color="primary" />
                <Typography color="primary">New Group</Typography>
              </MenuItemStyled>
            </Select>
          </FormControl>
        )}
      </Grid2>
      {showAddLevel && (
        <AddGroupDialog
          clientCode={report.clientCode}
          onAdded={(group) => {
            setGroupId(group.id);
            sendNotification(report_groups.created);
          }}
          onClose={() => setShowAddLevel(false)}
        />
      )}
      <DialogActions sx={{ px: 2.5, py: 1.5 }}>
        <Button variant="text" color="secondary" disabled={saving} onClick={onClose}>
          Close
        </Button>
        <LoadingButton variant="contained" disabled={saving || !isFormValid} loading={saving} onClick={handleSave}>
          {saveBtnTitle}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
