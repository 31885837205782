import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import ActionsMenuButton from "../../../../../shared/components/ActionsMenuButton";
import { Investor } from "../../../../api/adminApi";
import { Category } from "../../../../api/types/accessTypes";
import { ObjectClassDefinition, ObjectPermissions } from "../../../../api/types/objectTypes";
import { useClientContext } from "../../../../context/ClientContext";
import FeatureToggleBoundary from "../../../common/FeatureToggleBoundary";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import ObjectTitle from "../../../common/ObjectTitle";
import FullHeightFlexPage from "../../../common/layout/FullHeightFlexPage";
import StretchedScrollableContent from "../../../common/layout/StretchedScrollableContent";
import { getDataCatalogueEntityType } from "../../../entityFields/entityTypesHelper";
import EntityCardFiles from "../../../entityFields/files/EntityCardFiles";
import DeleteInvestorDialog from "./DeleteInvestorDialog";
import InvestorDetailsCapitalActivityTab from "./capital-activity/InvestorDetailsCapitalActivityTab";
import InvestorCommunicationMatrixTab from "./communication-matrix/InvestorCommunicationMatrixTab";
import InvestorDetailsDocumentsTab from "./documents/InvestorDetailsDocumentsTab";
import InvestorFundraisingDocumentsTab from "./fundraising-documents/InvestorFundraisingDocumentsTab";
import InvestorDetailsInvestmentsTab from "./investments/InvestorDetailsInvestmentsTab";
import InvestorMainInfoTab from "./main-info/InvestorMainInfoTab";

const tabPanelSx = { height: "100%", m: 0, px: 3, pt: 2.5, pb: 0 };

interface Props {
  investor: Investor;
  categories: Category[];
  objectDefinition: ObjectClassDefinition;
  objectPermissions: ObjectPermissions;
  onInvestorDetailsChange: (details: Partial<Investor>) => void;
}

interface DialogState {
  investorToDelete?: Investor;
  openDialog: "delete_investor" | undefined;
}

const InvestorDetailsPage = ({
  investor,
  categories,
  objectDefinition,
  objectPermissions,
  onInvestorDetailsChange,
}: Props) => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const { hasAnyPermission, isPortalFeatureEnabled } = useClientContext();

  const [selectedTab, setSelectedTab] = useState(tab ?? "main");
  const [dialogState, setDialogState] = useState<DialogState>({ openDialog: undefined });

  const hasPermissionsToManageData = hasAnyPermission(objectPermissions.objectDataWritePermissions);
  const hasPermissionsToViewFiles = hasAnyPermission(objectPermissions.filesReadPermissions);
  const hasPermissionsToManageFiles = hasAnyPermission(objectPermissions.filesWritePermissions);
  const hasPermissionsToViewFundraisings = hasAnyPermission(["ViewFundraisings", "ManageFundraisings"]);

  const onTabChange = (newTab: string) => {
    setSelectedTab(newTab);
    window.history.replaceState({}, document.title, window.location.pathname.replace(/\/[^/]+$/, `/${newTab}`));
  };

  const handleInvestorDeleted = () => {
    setDialogState({ openDialog: undefined });
    navigate("..");
  };

  const allowInvestorDeletion = false; // #6829 not allowed until allowed on the backend

  return (
    <>
      <TabContext value={selectedTab}>
        <FullHeightFlexPage>
          <Box>
            <GeneralPageHeader
              TitleComponent={<ObjectTitle title={investor.title} typeName={objectDefinition.objectName} />}
              hideBorderLine
              showDefaultBackButtonTo=".."
            >
              {hasPermissionsToManageData && allowInvestorDeletion && (
                <ActionsMenuButton
                  items={[
                    {
                      icon: <DeleteOutlineIcon color="error" />,
                      label: "Delete",
                      onClick: () => setDialogState({ openDialog: "delete_investor", investorToDelete: investor }),
                      disabled: !hasPermissionsToManageData,
                    },
                  ]}
                />
              )}
            </GeneralPageHeader>
            <TabList onChange={(_, tab) => onTabChange(tab)}>
              <Tab sx={{ ml: 3 }} label="Main Info" value="main" />
              <Tab label="Investments" value="investments" />
              <Tab label="Capital Activity" value="capitalActivity" />
              <Tab label="Investor Documents" value="documents" />
              {hasPermissionsToViewFundraisings && <Tab label="Fundraising Documents" value="fundraising-documents" />}
              {hasPermissionsToViewFiles && isPortalFeatureEnabled("EntityFilesEnabled") && (
                <Tab label="Files" value="files" />
              )}
              <Tab label="Communication Matrix" value="communication-matrix" />
            </TabList>
          </Box>
          <StretchedScrollableContent>
            <TabPanel value="main" sx={{ ...tabPanelSx, backgroundColor: "background.grey" }}>
              <InvestorMainInfoTab
                investor={investor}
                objectDefinition={objectDefinition}
                objectPermissions={objectPermissions}
                onInvestorDetailsChange={onInvestorDetailsChange}
              />
            </TabPanel>
            <TabPanel value="investments" sx={tabPanelSx}>
              <InvestorDetailsInvestmentsTab investorId={investor.id} />
            </TabPanel>
            <TabPanel value="capitalActivity" sx={tabPanelSx}>
              <InvestorDetailsCapitalActivityTab investor={investor} />
            </TabPanel>
            <TabPanel value="documents" sx={tabPanelSx}>
              <InvestorDetailsDocumentsTab
                investorId={investor.id}
                categories={categories}
                investorFunds={investor.funds}
              />
            </TabPanel>
            {hasPermissionsToViewFundraisings && (
              <TabPanel value="fundraising-documents" sx={tabPanelSx}>
                <InvestorFundraisingDocumentsTab
                  investorId={investor.id}
                  categories={categories}
                  investorFunds={investor.funds}
                />
              </TabPanel>
            )}
            {hasPermissionsToViewFiles && (
              <FeatureToggleBoundary featureToggle="EntityFilesEnabled">
                <TabPanel value="files" sx={{ height: "100%", m: 0, px: 0, py: 0 }}>
                  <EntityCardFiles
                    entityType={getDataCatalogueEntityType(objectDefinition.objectType)}
                    entityId={investor.id}
                    entityName={investor.title}
                    hasPermissionsToManage={hasPermissionsToManageFiles}
                  />
                </TabPanel>
              </FeatureToggleBoundary>
            )}
            <TabPanel value="communication-matrix" sx={tabPanelSx}>
              <InvestorCommunicationMatrixTab investor={investor} categories={categories} />
            </TabPanel>
          </StretchedScrollableContent>
        </FullHeightFlexPage>
      </TabContext>
      {dialogState.investorToDelete && (
        <DeleteInvestorDialog
          open={dialogState.openDialog === "delete_investor"}
          onClose={() => setDialogState({ openDialog: undefined })}
          onDeleted={handleInvestorDeleted}
          investor={dialogState.investorToDelete}
        />
      )}
    </>
  );
};

export default InvestorDetailsPage;
