import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import { useCallback, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import { createApiResponse, getStatusCodeFromError } from "../shared/api/axiosHelper";
import { updateClientCode } from "../shared/api/clientApiContext";
import { ssoApi } from "../shared/api/sso";
import { useAuth } from "../shared/auth/hooks";
import { redirectToLoginSignin } from "../shared/auth/internal/redirects";
import AuthenticationFailed from "../shared/components/AuthenticationFailed";
import ErrorBoundary from "../shared/components/ErrorBoundary";
import GlobalNotificationErrorSnackbar from "../shared/components/GlobalNotificationErrorSnackbar";
import GlobalNotificationSnackbar from "../shared/components/GlobalNotificationSnackbar";
import Intercom from "../shared/components/Intercom";
import FullScreenLoader from "../shared/components/fullScreenLoader/FullScreenLoader";
import InlineLoader from "../shared/components/inlineLoader/InlineLoader";
import { ClientPermissionsContextProvider } from "../shared/contexts/ClientPermissionsContext";
import { NotificationContextProvider } from "../shared/contexts/NotificationContext";
import useFetch from "../shared/hooks/useFetch";
import { logError } from "../shared/logging";
import { applyDateFnsLocaleFromBrowser } from "../shared/utilities/dateLocalization";
import Main from "./Main";
import Pages from "./Pages";
import adminApi, { User, UserData } from "./api/adminApi";
import { ClientData } from "./api/types/clientTypes";
import ConsentAgreementContainer from "./components/consentAgreement/ConsentAgreementContainer";
import { getUnsignedGeneralAgreements } from "./components/consentAgreement/consentAgreementService";
import { Client, ClientContextProvider } from "./context/ClientContext";
import { UserContextProvider } from "./context/UserContext";
import storage from "./storage/storage";

const App = () => {
  const { client } = useParams();
  const clientParam = client ? client.toLowerCase() : undefined;

  const [user, setUser] = useState<User>();
  const [userData, setUserData] = useState<UserData>();
  const [clientData, setClientData] = useState<Record<string, ClientData | undefined>>();
  const [unauthorized, setUnauthorized] = useState(false);

  const navigate = useNavigate();

  const onAuthenticated = useCallback(() => {
    const fetchUserData = async () => {
      try {
        const userDataResp = await adminApi.getIdentity();
        setUser(userDataResp.data);

        if (userDataResp.data && getUnsignedGeneralAgreements(userDataResp.data.consentAgreements).length === 0) {
          const userData = await adminApi.getUserData(userDataResp.data);
          setUserData(userData.data);
        }
      } catch (error) {
        if (getStatusCodeFromError(error) === 401) {
          await ssoApi.logout();
          setUnauthorized(true);
        } else {
          navigate("/error");
        }
      }
    };
    fetchUserData();
  }, [navigate]);

  const currentClient = useMemo(() => {
    if (userData === undefined) {
      return undefined;
    }

    const currentClientPermissions = userData.permissions.find((p) => p.clientCode.toLowerCase() === clientParam);
    if (!currentClientPermissions) {
      return undefined;
    }

    updateClientCode(currentClientPermissions.clientCode);

    const currentClientInfo = userData.clients.find((c) => c.clientCode === currentClientPermissions.clientCode);

    const client: Client = {
      ...currentClientPermissions,
      organizationEmailDomain: currentClientInfo?.organizationDomain,
      branding: currentClientInfo?.branding ?? {},
      businessCentralApp: currentClientInfo?.businessCentralApp,
      investorRelationsApp: currentClientInfo?.investorRelationsApp,
      investorPortalApp: currentClientInfo?.investorPortalApp,
      fundraisingApp: currentClientInfo?.fundraisingApp,
      portfolioMonitoringApp: currentClientInfo?.portfolioMonitoringApp,
      reportingPortalApp: currentClientInfo?.reportingPortalApp,
      apiGatewayApp: currentClientInfo?.apiGatewayApp,
      salesforceApp: currentClientInfo?.salesforceApp,
      plaidApp: currentClientInfo?.plaidApp,
      yodleeApp: currentClientInfo?.yodleeApp,
      saltEdgeApp: currentClientInfo?.saltEdgeApp,
      entityLevelAccess: currentClientInfo?.entityLevelAccess,
      clientTitle: currentClientInfo?.title ?? "",
      clientType: currentClientInfo?.type ?? "FundManager",
      expenseManagementPortalApp: currentClientInfo?.expenseManagementPortalApp,
      dataBackupApp: currentClientInfo?.dataBackupApp,
      passthroughApp: currentClientInfo?.passthroughApp,
      dealCloudApp: currentClientInfo?.dealCloudApp,
      companyType: currentClientInfo?.companyType ?? "Production",
    };

    return client;
  }, [clientParam, userData]);

  const updateUserData = useCallback(async () => {
    try {
      if (user) {
        const userData = await adminApi.getUserData(user);
        setUserData(userData.data);
      }
    } catch (error) {
      logError(error, "UpdateUserData");
    }
  }, [user]);

  const getClientData = useCallback(() => {
    if (!currentClient) {
      return Promise.resolve(createApiResponse(undefined));
    }

    return adminApi.getClientData(currentClient.clientType);
  }, [currentClient]);

  useFetch(getClientData, (data) => {
    if (data && clientParam) {
      setClientData((prevData) => ({
        ...prevData,
        [clientParam]: {
          settings: data.settings,
          dictionaries: data.dictionaries,
        },
      }));
    }
  });

  const getMenuBadges = useCallback(() => {
    if (!currentClient || currentClient.clientType === "FundAdmin") {
      return Promise.resolve(createApiResponse(undefined));
    }

    return adminApi.getMenuBadges();
  }, [currentClient]);

  const [menuBadges, , { fetch: fetchMenuBadges }] = useFetch(getMenuBadges);

  const onNewClientSelected = useCallback((newClientCode: string) => navigate(`/${newClientCode}`), [navigate]);

  const isAuthenticated = useAuth(onAuthenticated, redirectToLoginSignin);

  if (!isAuthenticated) {
    return <FullScreenLoader title="Please wait." subtitle="You are being redirected for Authentication..." />;
  }

  if (unauthorized) {
    return <AuthenticationFailed />;
  }

  if (userData === undefined) {
    return <InlineLoader />;
  }

  const currentClientPermissions = userData.permissions.find((p) => p.clientCode.toLowerCase() === clientParam);
  if (!currentClientPermissions && userData.permissions.length > 0) {
    const clientCodes = userData.permissions.map((p) => p.clientCode);
    const recentClientCode = storage.getRecentClients().find((c) => clientCodes.includes(c));
    const selectedClientCode = recentClientCode ?? clientCodes[0] ?? "";
    return <Navigate to={`/${selectedClientCode}`} />;
  }

  const currentClientData = clientData?.[clientParam || ""];
  if (user === undefined || currentClient === undefined || currentClientData === undefined) {
    return <InlineLoader />;
  }

  const consentAgreementsToAccept = getUnsignedGeneralAgreements(user.consentAgreements);
  if (consentAgreementsToAccept.length > 0) {
    return <ConsentAgreementContainer consentAgreements={consentAgreementsToAccept} />;
  }

  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={applyDateFnsLocaleFromBrowser()}>
        <UserContextProvider userData={userData}>
          {currentClientData.settings.intercomAuthentication !== undefined && (
            <Intercom
              fullName={userData.name}
              email={userData.email}
              settings={currentClientData.settings.intercomAuthentication}
            />
          )}
          <ClientContextProvider
            clientSettings={currentClientData.settings}
            clientDictionaries={currentClientData.dictionaries}
            menuBadges={menuBadges}
            updateMenuBadges={fetchMenuBadges}
            client={currentClient}
            onNewClientSelected={onNewClientSelected}
            updateUserData={updateUserData}
          >
            <ClientPermissionsContextProvider
              permissions={currentClient.permissions}
              permissionSets={userData.permissions}
            >
              <NotificationContextProvider>
                <Main>
                  <Pages />
                </Main>
                <GlobalNotificationSnackbar />
                <GlobalNotificationErrorSnackbar />
              </NotificationContextProvider>
            </ClientPermissionsContextProvider>
          </ClientContextProvider>
        </UserContextProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
};

export default App;
