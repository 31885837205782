import {
  DataCollectionSubmissionStatus,
  ImportSubmissionResponse,
  ReportingPeriod,
} from "../../../shared/api/dataCollectionTypes";
import { DataSubmissionLayout, ResolvedBlockContent } from "../../../shared/api/inputFormTypes";
import { PagingParams } from "../../../shared/api/types";

export interface DataCollectionSubmissionInfo {
  id: string;
  recipientName: string;
  status: DataCollectionSubmissionStatus;
  auditory: DataCollectionRequestAuditory;
  requestTemplateId: string;
  requestTemplateName: string;
  reportingDate: string;
  dueDate: string;
  submittedBy?: string;
  submittedAt?: string;
}

export interface DataCollectionSubmissionDetails {
  id: string;
  dataCollectionRequestId: string;
  requestName: string;
  recipientObjectId: string;
  recipientName: string;
  status: DataCollectionSubmissionStatus;
  auditory: DataCollectionRequestAuditory;
  requestTemplateId: string;
  requestTemplateName: string;
  reportingDate: string;
  dueDate: string;
  submittedBy?: string;
  submittedAt?: string;
  changesRequestedBy?: string;
  changesRequestedAt?: string;
  blockContents: ResolvedBlockContent[];
  layout: DataSubmissionLayout;
}

export interface DataSubmissionConfigurationInfo {
  id: string;
  name: string;
}

export interface DataCollectionRequestInfo {
  id: string;
  name: string;
  templateId: string;
  templateName: string;
  auditory: DataCollectionRequestAuditory;
  status: DataCollectionRequestStatus;
  reportingDate: string;
  createdAt: string;
  publishedAt?: string;
  submittedSubmissions: number;
  totalSubmissions: number;
}

export interface DataCollectionRequestDetails {
  id: string;
  name: string;
  templateId: string;
  templateName: string;
  auditory: DataCollectionRequestAuditory;
  status: DataCollectionRequestStatus;
  reportingDate: string;
  createdAt: string;
  publishedAt?: string;
  submittedSubmissions: number;
  submissions: RequestSubmissionInfo[];
  scope: DataCollectionRequestScope;
  templateVersion: number;
  templateScenario: string;
  templateReportingPeriod: ReportingPeriod;
  recipientObjectIds: string[];
  dueDate: string;
}

export interface RequestSubmissionInfo {
  id: string;
  status: DataCollectionSubmissionStatus;
  dueDate: string;
  recipientObjectId: string;
  recipientName: string;
  auditoryList: RequestSubmissionAuditory[];
  submittedAt?: string;
  submittedByUserName?: string;
}

export interface SearchSubmissionsRequest {
  paging: PagingParams;
  searchTerm?: string;
  requestTemplateIds?: string[];
  recipientObjectIds?: string[];
  statuses?: DataCollectionSubmissionStatus[];
  auditory?: DataCollectionRequestAuditory[];
  reportingDateFrom?: string;
  reportingDateTo?: string;
}

export interface RequestSubmissionAuditory {
  id: string;
  name: string;
}

export const allDataCollectionRequestAuditoryValues = ["PortfolioCompany", "Internal"] as const;
export type DataCollectionRequestAuditory = (typeof allDataCollectionRequestAuditoryValues)[number];

export const allDataCollectionRequestStatuses = ["Draft", "InProgress", "Completed"] as const;
export type DataCollectionRequestStatus = (typeof allDataCollectionRequestStatuses)[number];

export interface SearchDataCollectionRequestRequest {
  paging: PagingParams;
  searchTerm?: string;
  templateIds?: string[];
  statuses?: DataCollectionRequestStatus[];
  auditory?: DataCollectionRequestAuditory[];
  reportingDateFrom?: string;
  reportingDateTo?: string;
}

export interface CreateDataCollectionRequestRequest {
  templateId: string;
  name: string;
  reportingDate: string;
  dueDate: string;
}

export interface UpdateDataCollectionRequestRequest {
  name?: string;
  reportingDate?: string;
  dueDate?: string;
}

export interface DataCollectionRequestTemplateVersion {
  id: string;
  name: string;
  version: number;
  templateId: string;
  scenarioName: string;
  scenarioId: string;
  auditory: DataCollectionRequestAuditory;
  recipientObjectIds: string[];
  reportingPeriod: ReportingPeriod;
  scope: DataCollectionRequestScope;
  contactCategories: string[];
  internalUserPermissionCategories: string[];
}

type DataCollectionRequestScope = "PortfolioMonitoring";

export interface RequestSubmissionOperationRequest {
  submissionIds: string[];
}

export interface PublishDataRequestSubmissionsRequest extends RequestSubmissionOperationRequest {
  sendEmailNotifications: boolean;
}

export interface AddRequestSubmissionsRequest {
  recipientObjectIds: string[];
}

export type ImportSubmissionBlockInputResponse = ImportSubmissionResponse<DataCollectionSubmissionDetails>;

export interface PublishRequestRequest {
  sendEmailNotifications: boolean;
}
