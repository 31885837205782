import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Box, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import ActionsMenuButton from "../../../shared/components/ActionsMenuButton";
import ExpandableBox from "../../../shared/components/ExpandableBox";
import UserAvatar from "../../../shared/components/UserAvatar";
import HtmlPreview from "../../../shared/components/htmlEditor/HtmlPreview";
import { convertISODateTime } from "../../../shared/utilities/dateUtils";
import { useUserContext } from "../../context/UserContext";
import { useNotesContext } from "./NotesContext";
import { NoteItem } from "./notesState";

interface Props {
  note: NoteItem;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const NoteCard = ({ note, onEdit, onDelete }: Props) => {
  const { hasPermissionsToManageNotes } = useNotesContext();
  const { userId } = useUserContext();

  const handleEditClick = () => {
    onEdit(note.id);
  };

  const handleDeleteClick = () => {
    onDelete(note.id);
  };

  const isCreatedByCurrentUser = note.createdBy === userId;

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          sx={{ pb: 1 }}
          avatar={note.createdByUsername && <UserAvatar size="small" userName={note.createdByUsername} />}
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle2">{isCreatedByCurrentUser ? "You" : note.createdByUsername}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {convertISODateTime(note.updatedAt)}
                </Typography>
              </Stack>
              {hasPermissionsToManageNotes && (
                <ActionsMenuButton
                  items={[
                    {
                      disabled: !isCreatedByCurrentUser,
                      icon: <EditIcon color="secondary" />,
                      label: "Edit",
                      onClick: handleEditClick,
                    },
                    {
                      icon: <DeleteIcon color="error" />,
                      label: "Delete",
                      onClick: handleDeleteClick,
                    },
                  ]}
                />
              )}
            </Box>
          }
        />
        <CardContent sx={{ pt: 0, pb: 1.5, pl: 7 }}>
          <ExpandableBox maxHeight={100}>
            <HtmlPreview htmlContent={note.htmlContent} />
          </ExpandableBox>
        </CardContent>
      </Card>
    </>
  );
};

export default NoteCard;
