import { Navigate, Outlet, Route, Routes, useParams } from "react-router";
import GenericObjectDetailsPage from "./components/entityFields/grid/GenericObjectDetailsPage";
import NewObjectDetailsPage from "./components/entityFields/grid/NewObjectDetailsPage";
import ObjectsPage from "./components/entityFields/grid/ObjectsPage";
import ApiGatewayPage from "./components/pages/api-gateway/ApiGatewayPage";
import ApprovalsPage from "./components/pages/approvals/ApprovalsPage";
import ApprovalDetailsPage from "./components/pages/approvals/details/ApprovalDetailsPage";
import AssistantPage from "./components/pages/assistant/AssistantPage";
import ChangeRequestsPage from "./components/pages/change-requests/ChangeRequestsPage";
import ChangeRequestDetailsPage from "./components/pages/change-requests/details/ChangeRequestDetailsPage";
import DealCloudConnectorPage from "./components/pages/crm-connector/DealCloudConnectorPage";
import SalesforceConnectorPage from "./components/pages/crm-connector/SalesforceConnectorPage";
import { DataBackupPage } from "./components/pages/data-backup/DataBackupPage";
import DataRequestTemplatesPage from "./components/pages/data-collections/data-request-templates/DataRequestTemplatesPage";
import DataRequestsPage from "./components/pages/data-collections/data-requests/DataRequestsPage";
import DataRequestPage from "./components/pages/data-collections/data-requests/data-request-details/DataRequestPage";
import DataSubmissionsPage from "./components/pages/data-collections/data-submissions/DataSubmissionsPage";
import DataSubmissionPage from "./components/pages/data-collections/data-submissions/data-submission-details/DataSubmissionPage";
import DataModelPage from "./components/pages/data-model/DataModelPage";
import ObjectDefinitionWrapperPage from "./components/pages/data-model/ObjectDefinitionWrapperPage";
import ObjectLayoutPage from "./components/pages/data-model/layouts/ObjectLayoutPage";
import ObjectDetailsPage from "./components/pages/data-model/object-details/ObjectDetailsPage";
import SnowflakeConnectorPage from "./components/pages/dwh-connector/SnowflakeConnectorPage";
import InvoicesPage from "./components/pages/expense-management/invoices/InvoicesPage";
import InvoiceDetailsPage from "./components/pages/expense-management/invoices/invoice-details/InvoiceDetailsPage";
import VendorDetailsPage from "./components/pages/expense-management/vendors/VendorDetailsPage";
import VendorsPage from "./components/pages/expense-management/vendors/VendorsPage";
import CompanyFilesPage from "./components/pages/files/CompanyFilesPage";
import FundStructureContactPage from "./components/pages/fund-structure/contacts/FundStructureContactPage";
import FundStructureContactsPage from "./components/pages/fund-structure/contacts/FundStructureContactsPage";
import ContactDocumentActivityPage from "./components/pages/fund-structure/contacts/documents/ContactDocumentActivityPage";
import ContactFundraisingDocumentActivityPage from "./components/pages/fund-structure/contacts/fundraising-documents/ContactFundraisingDocumentActivityPage";
import FundStructureFundPage from "./components/pages/fund-structure/funds/FundStructureFundPage";
import FundStructureFundsPage from "./components/pages/fund-structure/funds/FundStructureFundsPage";
import FundStructureInvestorPage from "./components/pages/fund-structure/investors/FundStructureInvestorPage";
import FundStructureInvestorsPage from "./components/pages/fund-structure/investors/FundStructureInvestorsPage";
import InvestorDocumentActivityPage from "./components/pages/fund-structure/investors/documents/InvestorDocumentActivityPage";
import InvestorFundraisingDocumentActivityPage from "./components/pages/fund-structure/investors/fundraising-documents/InvestorFundraisingDocumentActivityPage";
import GroupsPage from "./components/pages/groups/GroupsPage";
import HomePage from "./components/pages/home/HomePage";
import ImportDataPage from "./components/pages/import-data/ImportDataPage";
import ImportDataReviewPage from "./components/pages/import-data/ImportDataReviewPage";
import ImportCsvDataPage from "./components/pages/import-data/csv-import/ImportCsvDataPage";
import ImportDataDetailsPage from "./components/pages/import-data/details/ImportDataDetailsPage";
import DocumentCollectionsPage from "./components/pages/insights/DocumentCollectionsPage";
import DocumentCollectionPage from "./components/pages/insights/document-collection/DocumentCollectionPage";
import UserConsentDetailsPage from "./components/pages/investor-portal/consents/UserConsentDetailsPage";
import InvestorPortalDevTools from "./components/pages/investor-portal/dev-tools/InvestorPortalDevTools";
import DocumentActivityPage from "./components/pages/investor-portal/document-activity/DocumentActivityPage";
import EmailsPage from "./components/pages/investor-portal/emails/EmailsPage";
import FundraisingsPage from "./components/pages/investor-portal/fundraising/FundraisingsPage";
import FundraisingDetailsPage from "./components/pages/investor-portal/fundraising/fundraising-details/FundraisingDetailsPage";
import InvestorPortalSettingsPage from "./components/pages/investor-portal/settings/InvestorPortalSettingsPage";
import InvestorPortalStylePage from "./components/pages/investor-portal/style/InvestorPortalStylePage";
import MetricsPage from "./components/pages/metrics/MetricsPage";
import PassthroughPage from "./components/pages/passthrough/PassthroughPage";
import FundInvestmentDetailsPage from "./components/pages/portfolio-monitoring/fund-investments/FundInvestmentDetailsPage";
import FundInvestmentsPage from "./components/pages/portfolio-monitoring/fund-investments/FundInvestmentsPage";
import PortfolioCompaniesPage from "./components/pages/portfolio-monitoring/portfolio-companies/PortfolioCompaniesPage";
import PortfolioCompanyDetailsPage from "./components/pages/portfolio-monitoring/portfolio-companies/PortfolioCompanyDetailsPage";
import PortfolioPortalPage from "./components/pages/portfolio-portal/PortfolioPortalPage";
import CompanyApiReportsPage from "./components/pages/reporting/CompanyApiReportsPage";
import CompanyUiReportsPage from "./components/pages/reporting/CompanyUiReportsPage";
import OrganizationTemplatesPage from "./components/pages/reporting/OrganizationTemplatesPage";
import CompanyApiReportsContainer from "./components/pages/reporting/reports/api-reports/CompanyApiReportsContainer";
import CompanyUiReportsContainer from "./components/pages/reporting/reports/ui-reports/CompanyUiReportsContainer";
import OrganizationTemplatesContainer from "./components/pages/reporting/templates/OrganizationTemplatesContainer";
import ScenarioDetailsPage from "./components/pages/scenarios/ScenarioDetailsPage";
import ScenariosPage from "./components/pages/scenarios/ScenariosPage";
import GeneralCompanySettingsPage from "./components/pages/settings/company/GeneralCompanySettingsPage";
import IntegrationsPage from "./components/pages/settings/integrations/IntegrationsPage";
import MembersSettingsPage from "./components/pages/settings/members/MembersSettingsPage";
import ApiOrganizationReports from "./components/pages/settings/organization-reports/api-reports/OrganizationReports";
import ApiOrganizationReportsPage from "./components/pages/settings/organization-reports/api-reports/OrganizationReportsPage";
import UiOrganizationReports from "./components/pages/settings/organization-reports/ui-reports/OrganizationReports";
import UiOrganizationReportsPage from "./components/pages/settings/organization-reports/ui-reports/OrganizationReportsPage";
import { useClientContext } from "./context/ClientContext";
import { pageRoutes } from "./routes";

const Pages = () => {
  const { client } = useParams();
  const { hasAnyPermission, hasPermissions, isFundAdmin, isPortalFeatureEnabled } = useClientContext();

  const hasAccessToInvestorPortalSettings = hasAnyPermission([
    "ViewInvestorPortalSettings",
    "ManageInvestorPortalSettings",
  ]);

  const hasAccessToFundStructure = hasAnyPermission(["ViewFundStructure", "ManageFundStructure"]);

  const hasAccessToDocumentCollections = hasAnyPermission(["ViewDocumentCollections", "ManageDocumentCollections"]);

  const hasAccessToDocumentActivity =
    hasAccessToFundStructure && hasPermissions(["ViewInvestorPortalDocumentViewsAnalytics"]);

  const hasAccessToFundraising =
    hasAccessToInvestorPortalSettings && hasAnyPermission(["ViewFundraisings", "ManageFundraisings"]);

  const hasAccessToOrganizationUsers =
    hasAnyPermission(["ManageOrganizationUsers", "ViewOrganizationUsers"]) && isFundAdmin;

  const hasAccessToCompanySettings = hasAnyPermission(["ViewCompanySettings", "ManageCompanySettings"]);

  const hasAccessToIntegrationSettings = hasAnyPermission(["ViewClientIntegrations", "ManageClientIntegrations"]);

  const hasAccessToAssistant = hasAnyPermission(["UseAiAssistant"]) && isPortalFeatureEnabled("AiAssistantEnabled");

  const hasAccessToOrganizationReports = hasAnyPermission(["ManageOrganizationReports"]) && isFundAdmin;

  const hasAccessToReportingPortal = hasAnyPermission(["ManageReports", "ViewReports"]);

  const hasAccessToCompanyFiles =
    hasAnyPermission(["ViewAllCompanyFiles", "ManageAllCompanyFiles"]) && isPortalFeatureEnabled("EntityFilesEnabled");

  const hasAccessToExpenseManagement = hasAnyPermission(["ViewExpenses", "ManageExpenses"]);

  const hasAccessToPortfolioMonitoring = hasAnyPermission([
    "ViewPortfolioMonitoring",
    "ManagePortfolioMonitoringAssignedDataSubmissions",
    "ManagePortfolioMonitoring",
  ]);

  const hasAccessToImportData = hasAnyPermission([
    "ViewInvestorRelationsDataImports",
    "ManageInvestorRelationsDataImports",
    "ViewPortfolioMonitoringDataImports",
    "ManagePortfolioMonitoringDataImports",
    "ViewExpenseManagementDataImports",
    "ManageExpenseManagementDataImports",
  ]);

  const hasAccessToInvestorRequests = hasAnyPermission(["ViewInvestorRequests"]);

  const hasAccessToOrganizationDataModel =
    hasAnyPermission(["ViewOrganizationDataModel", "ManageOrganizationDataModel"]) && isFundAdmin;

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={`/${client}/${pageRoutes.home}`} />} />
      <Route path={pageRoutes.home} element={<HomePage />} />
      {hasAccessToAssistant && <Route path={pageRoutes.assistant} element={<AssistantPage />} />}
      {hasAccessToInvestorPortalSettings && (
        <Route path={pageRoutes.investorRelations}>
          <Route path={pageRoutes.investorPortalSettings} element={<InvestorPortalSettingsPage />} />
          <Route path={pageRoutes.investorPortalStyle} element={<InvestorPortalStylePage />} />
          <Route path={pageRoutes.userConsents} element={<Outlet />}>
            <Route path=":name" element={<UserConsentDetailsPage />} />
          </Route>
          <Route path={pageRoutes.investorEmails} element={<EmailsPage />} />
          {hasAccessToDocumentCollections && (
            <Route path={pageRoutes.documentCollections} element={<Outlet />}>
              <Route index element={<DocumentCollectionsPage />} />
              <Route path=":id" element={<DocumentCollectionPage />} />
            </Route>
          )}
          {hasAccessToFundraising && (
            <Route path={pageRoutes.fundraisings} element={<Outlet />}>
              <Route index element={<FundraisingsPage />} />
              <Route path=":id" element={<FundraisingDetailsPage />} />
            </Route>
          )}
          {hasAccessToDocumentActivity && (
            <Route path={pageRoutes.documentActivity} element={<Outlet />}>
              <Route index element={<DocumentActivityPage />} />
            </Route>
          )}
          {hasAccessToFundStructure && (
            <Route path={pageRoutes.funds} element={<Outlet />}>
              <Route index element={<FundStructureFundsPage />} />
              <Route path=":id/:tab" element={<FundStructureFundPage />} />
            </Route>
          )}
          {hasAccessToFundStructure && (
            <Route path={pageRoutes.investors} element={<Outlet />}>
              <Route index element={<FundStructureInvestorsPage />} />
              <Route path=":id/:tab" element={<FundStructureInvestorPage />} />
              {hasAccessToDocumentActivity && (
                <Route
                  path={`:id/${pageRoutes.messages}/:postMessageRequestId/${pageRoutes.documents}/:attachmentId/${pageRoutes.activity}`}
                  element={<InvestorDocumentActivityPage />}
                />
              )}
              {hasAccessToDocumentActivity && (
                <Route
                  path={`:id/${pageRoutes.fundraisings}/:fundraisingId/${pageRoutes.documents}/:documentId/${pageRoutes.activity}`}
                  element={<InvestorFundraisingDocumentActivityPage />}
                />
              )}
            </Route>
          )}
          {hasAccessToFundStructure && (
            <Route path={pageRoutes.contacts} element={<Outlet />}>
              <Route index element={<FundStructureContactsPage />} />
              <Route path=":id/:tab" element={<FundStructureContactPage />} />
              {hasAccessToDocumentActivity && (
                <Route
                  path={`:id/${pageRoutes.messages}/:postMessageRequestId/${pageRoutes.documents}/:attachmentId/${pageRoutes.activity}`}
                  element={<ContactDocumentActivityPage />}
                />
              )}
              {hasAccessToDocumentActivity && (
                <Route
                  path={`:id/${pageRoutes.fundraisings}/:fundraisingId/${pageRoutes.documents}/:documentId/${pageRoutes.activity}`}
                  element={<ContactFundraisingDocumentActivityPage />}
                />
              )}
            </Route>
          )}
          {hasAccessToInvestorRequests && (
            <Route path={pageRoutes.requests} element={<Outlet />}>
              <Route index element={<ChangeRequestsPage />} />
              <Route path=":id" element={<ChangeRequestDetailsPage />} />
            </Route>
          )}
          <Route path={pageRoutes.investorPortalDevTools} element={<InvestorPortalDevTools />} />
        </Route>
      )}
      {hasAccessToReportingPortal && (
        <Route path={pageRoutes.smartViews}>
          <Route path={pageRoutes.companyReports} element={<CompanyUiReportsPage />}>
            <Route index element={<CompanyUiReportsContainer />} />
            <Route
              path={pageRoutes.reportLevels}
              element={<GroupsPage permissions={["ManageReports"]} title="Report Groups" type="report" />}
            />
          </Route>
          <Route path={pageRoutes.apiReports} element={<CompanyApiReportsPage />}>
            <Route index element={<CompanyApiReportsContainer />} />
          </Route>
        </Route>
      )}
      <Route path={pageRoutes.smartViews} element={<Outlet />}>
        {hasAccessToOrganizationReports && (
          <Route path={pageRoutes.organizationReports} element={<UiOrganizationReportsPage />}>
            <Route index element={<UiOrganizationReports />} />
          </Route>
        )}
        {hasAccessToOrganizationReports && (
          <Route path={pageRoutes.organizationApiReports} element={<ApiOrganizationReportsPage />}>
            <Route index element={<ApiOrganizationReports />} />
          </Route>
        )}
        <Route path={pageRoutes.reportTemplates} element={<OrganizationTemplatesPage />}>
          <Route index element={<OrganizationTemplatesContainer />} />
          <Route
            path={pageRoutes.reportLevels}
            element={
              <GroupsPage
                permissions={["ManageOrganizationReportTemplates"]}
                title="Report Template Groups"
                type="template"
              />
            }
          />
        </Route>
      </Route>
      <Route path={pageRoutes.settings}>
        {hasAccessToCompanySettings && (
          <Route path={pageRoutes.settingsCompanyGeneral} element={<GeneralCompanySettingsPage />} />
        )}
        {hasAccessToOrganizationUsers && <Route path={pageRoutes.settingsMembers} element={<MembersSettingsPage />} />}
        {hasAccessToIntegrationSettings && (
          <Route path={pageRoutes.settingsIntegrations} element={<IntegrationsPage />} />
        )}
        {hasAccessToImportData && (
          <Route path={pageRoutes.importData} element={<Outlet />}>
            <Route index element={<ImportDataPage />} />
            <Route path={pageRoutes.importCsvData} element={<ImportCsvDataPage />} />
            <Route path=":id" element={<Outlet />}>
              <Route path={pageRoutes.importDetails} element={<ImportDataDetailsPage />} />
              <Route path={pageRoutes.importReview} element={<ImportDataReviewPage />} />
            </Route>
          </Route>
        )}
        {isPortalFeatureEnabled("CustomObjectsEnabled") && hasAccessToCompanySettings && (
          <Route path={pageRoutes.records} element={<Outlet />}>
            <Route index element={<ObjectsPage />} />
            <Route path=":type/new" element={<NewObjectDetailsPage />} />
            <Route path=":type/:id" element={<GenericObjectDetailsPage />} />
          </Route>
        )}
        {hasAccessToOrganizationDataModel && (
          <Route path={pageRoutes.dataModel} element={<Outlet />}>
            <Route index element={<DataModelPage />} />
            <Route path={pageRoutes.orgObjects} element={<Outlet />}>
              <Route path=":objectType" element={<ObjectDefinitionWrapperPage />}>
                <Route index element={<ObjectDetailsPage />} />
                <Route path={`${pageRoutes.layout}/:configurationId`} element={<ObjectLayoutPage />} />
              </Route>
            </Route>
          </Route>
        )}
      </Route>
      {hasAccessToIntegrationSettings && (
        <Route path={pageRoutes.integration}>
          <Route path={pageRoutes.apiGateway} element={<ApiGatewayPage />} />
          <Route path={pageRoutes.snowflake} element={<SnowflakeConnectorPage />} />
          <Route path={pageRoutes.salesforce} element={<SalesforceConnectorPage />} />
          <Route path={pageRoutes.dealcloud} element={<DealCloudConnectorPage />} />
          <Route path={pageRoutes.dataBackup} element={<DataBackupPage />} />
          <Route path={pageRoutes.passthrough} element={<PassthroughPage />} />
        </Route>
      )}
      {hasAccessToCompanyFiles && <Route path={pageRoutes.files} element={<CompanyFilesPage />} />}
      {hasAccessToExpenseManagement && (
        <Route path={pageRoutes.expenseManagement}>
          <Route path={pageRoutes.invoices} element={<InvoicesPage />}>
            <Route path=":id" element={<InvoiceDetailsPage />} />
          </Route>
          <Route path={pageRoutes.vendors} element={<Outlet />}>
            <Route index element={<VendorsPage />} />
            <Route path=":id" element={<VendorDetailsPage />} />
          </Route>
        </Route>
      )}
      {hasAccessToPortfolioMonitoring && (
        <Route path={pageRoutes.portfolio}>
          <Route path={pageRoutes.fundInvestments} element={<Outlet />}>
            <Route index element={<FundInvestmentsPage />} />
            <Route path=":id" element={<FundInvestmentDetailsPage />} />
          </Route>
          <Route path={pageRoutes.portfolioCompanies} element={<Outlet />}>
            <Route index element={<PortfolioCompaniesPage />} />
            <Route path=":id" element={<PortfolioCompanyDetailsPage />} />
          </Route>
          <Route path={pageRoutes.dataRequestTemplates} element={<Outlet />}>
            <Route index element={<DataRequestTemplatesPage />} />
          </Route>
          <Route path={pageRoutes.dataRequests} element={<Outlet />}>
            <Route index element={<DataRequestsPage />} />
            <Route path=":id" element={<DataRequestPage />} />
          </Route>
          <Route path={pageRoutes.dataSubmissions} element={<Outlet />}>
            <Route index element={<DataSubmissionsPage />} />
            <Route path=":id" element={<DataSubmissionPage />} />
          </Route>
          <Route path={pageRoutes.metrics} element={<Outlet />}>
            <Route index element={<MetricsPage />} />
          </Route>
          <Route path={pageRoutes.scenarios} element={<Outlet />}>
            <Route index element={<ScenariosPage />} />
            <Route path=":id" element={<ScenarioDetailsPage />} />
          </Route>
          <Route path={pageRoutes.portfolioPortal} element={<Outlet />}>
            <Route index element={<PortfolioPortalPage />} />
          </Route>
        </Route>
      )}
      {hasAccessToFundStructure && isPortalFeatureEnabled("ApprovalsPocEnabled") && (
        <Route path={pageRoutes.workflows}>
          <Route path={pageRoutes.approvals} element={<Outlet />}>
            <Route index element={<ApprovalsPage />} />
            <Route path=":id" element={<ApprovalDetailsPage />} />
          </Route>
        </Route>
      )}
      <Route path="*" element={<Navigate replace to={`/${client}/${pageRoutes.home}`} />} />
    </Routes>
  );
};

export default Pages;
