import AddIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMoveOutlined";
import MoveDownIcon from "@mui/icons-material/MoveDownRounded";
import MoveUpIcon from "@mui/icons-material/MoveUpRounded";
import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { Divider } from "@mui/material";
import { useState } from "react";
import ActionsMenuButton from "../../../../../../../shared/components/ActionsMenuButton";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import EditFileSectionDialog from "./EditFileSectionDialog";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import {
  deleteDocumentsAction,
  FundraisingDocument,
  moveDocumentDownAction,
  moveDocumentUpAction,
  updateDocumentsSectionAction,
} from "./fundraisingDocumentsState";

interface Props {
  row: FundraisingDocument;
}

const FundraisingDocumentActionsCell = ({ row }: Props) => {
  const { isContentEditable } = useFundraisingDetailsPageContext();
  const { documentsState, setDocumentsState, onDownloadFile } = useFundraisingDocumentsContext();
  const [showNewSectionDialog, setShowNewSectionDialog] = useState(false);

  if (!isContentEditable || documentsState.isSaving) {
    return null;
  }

  const handleCreateNewSection = () => {
    setShowNewSectionDialog(true);
  };

  const handleNewSectionCreated = (section: string) => {
    setShowNewSectionDialog(false);
    setDocumentsState(updateDocumentsSectionAction([row.fileId], section));
  };

  const currentDocumentIndex = documentsState.documents.findIndex((d) => d.fileId === row.fileId);
  const previousDocument = documentsState.documents[currentDocumentIndex - 1];
  const nextDocument = documentsState.documents[currentDocumentIndex + 1];

  const canDownload = row.uploadStatus === undefined;

  const canMoveUp =
    (row.uploadStatus === undefined || row.uploadStatus === "ready_for_upload") &&
    previousDocument !== undefined &&
    row.section === previousDocument.section &&
    previousDocument.uploadStatus !== "error";

  const canMoveDown =
    (row.uploadStatus === undefined || row.uploadStatus === "ready_for_upload") &&
    nextDocument !== undefined &&
    row.section === nextDocument.section &&
    nextDocument.uploadStatus !== "error";

  const canChangeSection =
    (row.uploadStatus === undefined || row.uploadStatus === "ready_for_upload") &&
    documentsState.orderedSections.length > 1;

  return (
    <>
      <ActionsMenuButton
        items={[
          {
            label: "Download",
            icon: <DownloadIcon color="action" />,
            onClick: () => onDownloadFile(row.fileId),
            disabled: !canDownload,
          },
          {
            label: "Move up",
            icon: <MoveUpIcon color="action" />,
            onClick: () => setDocumentsState(moveDocumentUpAction(row.fileId)),
            disabled: !canMoveUp,
          },
          {
            label: "Move down",
            icon: <MoveDownIcon color="action" />,
            onClick: () => setDocumentsState(moveDocumentDownAction(row.fileId)),
            disabled: !canMoveDown,
          },
          {
            label: "Change section",
            icon: <DriveFileMoveIcon color="action" />,
            disabled: !canChangeSection,
            subMenuItems: [
              ...documentsState.orderedSections
                .filter((section) => section !== row.section)
                .map((section) => ({
                  label: section,
                  onClick: () => setDocumentsState(updateDocumentsSectionAction([row.fileId], section)),
                })),
              {
                label: "",
                component: <Divider key="change_section_menu_divider" />,
              },
              {
                label: "Create New",
                onClick: handleCreateNewSection,
                icon: <AddIcon color="primary" />,
                textProps: { color: "primary.main" },
              },
            ],
          },
          {
            label: "Delete",
            icon: <DeleteIcon color="error" />,
            onClick: () => setDocumentsState(deleteDocumentsAction([row.fileId])),
          },
        ]}
      />

      {showNewSectionDialog && (
        <EditFileSectionDialog onClose={() => setShowNewSectionDialog(false)} onCreated={handleNewSectionCreated} />
      )}
    </>
  );
};

export default FundraisingDocumentActionsCell;
