import { MetricCellValueUpdate, SaveSubmissionLayoutBlockInputRequest } from "../../../api/dataCollectionTypes";

export type EditedFormValuesState = Record<string, EditedFormBlockValuesState>;

interface EditedFormBlockValuesState {
  cellValueUpdates: MetricCellValueUpdate[];
}

export const getInitialEditedFormValuesState = (): EditedFormValuesState => ({});

// Selectors

export const isEditedFormValuesStateEmpty = (state: EditedFormValuesState): boolean =>
  Object.values(state).every((values) => values.cellValueUpdates.length === 0);

export const getFormUpdatesPerBlock = (
  state: EditedFormValuesState
): [string, SaveSubmissionLayoutBlockInputRequest][] => {
  const updates: [string, SaveSubmissionLayoutBlockInputRequest][] = [];

  for (const [blockId, blockValues] of Object.entries(state)) {
    const { cellValueUpdates } = blockValues;

    if (cellValueUpdates.length > 0) {
      updates.push([blockId, { cellValueUpdates: cellValueUpdates }]);
    }
  }

  return updates;
};

// Actions

type StateAction = (state: EditedFormValuesState) => EditedFormValuesState;

const areValuesFromSameCell = (a: MetricCellValueUpdate, b: MetricCellValueUpdate): boolean =>
  a.metricId === b.metricId && a.columnId === b.columnId && a.metricExtensionId === b.metricExtensionId;

export const addCellValueUpdateAction =
  (blockId: string, cellValueUpdate: MetricCellValueUpdate): StateAction =>
  (state) => {
    const prevBlockCellValues = state[blockId]?.cellValueUpdates ?? [];

    // If the value was already edited, replace it
    const valueToReplace = prevBlockCellValues.find((value) => areValuesFromSameCell(value, cellValueUpdate));

    const newBlockCellValues = valueToReplace
      ? prevBlockCellValues.map((value) => (value === valueToReplace ? cellValueUpdate : value))
      : [...prevBlockCellValues, cellValueUpdate];

    return {
      ...state,
      [blockId]: {
        cellValueUpdates: newBlockCellValues,
      },
    };
  };
