import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useReducer, useState } from "react";
import { DataCollectionSubmissionStatus } from "../../../../../shared/api/dataCollectionTypes";
import { DataCollectionSubmissionInfo } from "../../../../api/types/dataCollectionTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { FilterContextProvider } from "../../../common/filters/FilterContext";
import FiltersPanel from "../../../common/filters/FiltersPanel";
import { createReducer, filterRecords, getInitialState } from "../../../common/filters/filterState";
import DataSubmissionsGrid from "./DataSubmissionsGrid";
import {
  mySubmissionsFilterDefinitions,
  mySubmissionsSearchDefinition,
  selectOptionsResolver,
} from "./dataSubmissionsGridFilters";

interface Props {
  assignedDataSubmissions: DataCollectionSubmissionInfo[];
  loading: boolean;
  onRefresh: () => void;
}

const MyDataSubmissionsList = ({ assignedDataSubmissions, loading, onRefresh }: Props) => {
  const { clientCode } = useClientContext();

  const [statusFilterValue, setStatusFilterValue] = useState<DataCollectionSubmissionStatus>("Pending");

  const [filterState, dispatchFilters] = useReducer(
    createReducer<DataCollectionSubmissionInfo>(),
    getInitialState(
      `${clientCode}_my_data_submissions_filter_v1`,
      mySubmissionsFilterDefinitions,
      mySubmissionsSearchDefinition,
      []
    )
  );

  const allSelectedSubmissions = assignedDataSubmissions.filter((ds) => ds.status === statusFilterValue);

  const filteredSubmissions = filterRecords(filterState, allSelectedSubmissions);

  return (
    <FilterContextProvider
      filterState={filterState}
      dispatchFilters={dispatchFilters}
      getSelectOptions={selectOptionsResolver}
      allRowsForSelectOptions={assignedDataSubmissions}
    >
      <Stack height="100%" spacing={2}>
        <Stack direction="row" spacing={2}>
          <ToggleButtonGroup
            value={statusFilterValue}
            exclusive
            onChange={(_, value) => setStatusFilterValue(value)}
            sx={{ height: "2.25rem" }}
          >
            <ToggleButton value="Pending" sx={{ gap: 1 }}>
              Active
            </ToggleButton>
            <ToggleButton value="Completed" sx={{ gap: 1 }}>
              Completed
            </ToggleButton>
          </ToggleButtonGroup>

          <FiltersPanel
            totalCount={allSelectedSubmissions.length}
            recordCount={filteredSubmissions.length}
            isLoading={loading}
            onRefresh={onRefresh}
          />
        </Stack>

        <DataSubmissionsGrid rows={filteredSubmissions} isLoading={loading} />
      </Stack>
    </FilterContextProvider>
  );
};

export default MyDataSubmissionsList;
