import { EntityOptionFieldType } from "../../../shared/api/dictionaryTypes";
import { AccessPermission, EntriliaProduct, PagingParams } from "../../../shared/api/types";
import { PortalRole } from "./accessTypes";
import { MemberRole } from "./userManagementTypes";

export type FieldIdToValueMap = Record<string, unknown>;

export type FieldIdToLookupReferences = Record<string, LookupObjectReference[]>;

export interface ObjectWithFieldValuesBase {
  fieldValues: FieldIdToValueMap;
  logoUrl?: string;
  lookupObjects?: FieldIdToLookupReferences;
}

export interface ObjectFieldValues extends ObjectWithFieldValuesBase {
  id: string;
  objectType: string;
  createdAt?: string;
  updateAt?: string;
  name: string;
  lookupObjects: FieldIdToLookupReferences;
  displayValues: {
    primaryValue: string;
    secondaryValue?: string;
  };
}

export interface LookupObjectReference {
  objectId: string;
  primaryFieldValue: string;
  secondaryFieldValue?: string;
}

export type ObjectTypeToLookupReferences = Record<string, LookupObjectReference[]>;

type BusinessCentralType = "Entity" | "Contact" | "CommunicationGroup";

export interface SearchObjectsRequest {
  fieldIds?: string[];
  paging?: PagingParams;
}

export interface CreateObjectRequest {
  fieldValues: FieldIdToValueMap;
  entityTypeCode?: string;
}

export interface UpdateObjectRequest {
  fieldValues: FieldIdToValueMap;
}

// Used for Investor, Contact and Fund only
export interface FieldValuesResponse {
  objectId: string;
  externalId?: string;
  values: FieldValue[];
}

// Used for Investor, Contact and Fund only
export interface UpdateEntityFieldsRequest {
  values: FieldValue[];
}

interface FieldValue {
  fieldId: string;
  value?: unknown;
}

export interface PageLayout {
  configurationId?: string;
  objectType: string;
  fieldGroups: FieldGroup[];
}

export interface CreateOrganizationPageLayoutRequest {
  configurationId: string;
  fieldGroups: FieldGroup[];
}

export interface FieldGroup {
  name: string;
  fields: FieldReference[];
}

interface FieldReference {
  fieldId: string;
  isVisible: boolean;
}

export interface ObjectClassDefinitionInfo {
  objectType: string;
  objectName: string;
  source: ObjectClassDefinitionSource;
  businessCentralType?: BusinessCentralType;
  productAreas: EntriliaProduct[];
  supportedFeatures: ObjectFeature[];
}

export interface ObjectClassDefinition extends ObjectClassDefinitionInfo {
  fields: Field[];
  defaultGridFieldIds: string[];
  displayFields: {
    primaryFieldId: string;
    secondaryFieldId?: string;
  };
  objectContactCategoryType?: string;
  supportedContactRoles: PortalRole[];
  internalUserPermissionsCategoryType?: string;
  supportedInternalUserRoles: MemberRole[];
}

export interface ObjectClassDefinitionWithPermissions {
  objectClassDefinition: ObjectClassDefinition;
  objectPermissions: ObjectPermissions;
}

export interface ObjectClassDefinitionInfoWithPermissions {
  objectClassDefinition: ObjectClassDefinitionInfo;
  objectPermissions: ObjectPermissions;
}

export interface ObjectPermissions {
  objectDataReadPermissions: AccessPermission[];
  objectDataWritePermissions: AccessPermission[];
  filesReadPermissions: AccessPermission[];
  filesWritePermissions: AccessPermission[];
  notesReadPermissions: AccessPermission[];
  notesWritePermissions: AccessPermission[];
  objectContactsReadPermissions: AccessPermission[];
  objectContactsWritePermissions: AccessPermission[];
  objectInternalUsersReadPermissions: AccessPermission[];
  objectInternalUsersWritePermissions: AccessPermission[];
}

export const allObjectFeatures = [
  "Notes",
  "Files",
  "PortfolioMetrics",
  "DataImport",
  "InternalUserPermissions",
] as const;

export type ObjectFeature = (typeof allObjectFeatures)[number];

export type ObjectClassDefinitionSource = "System" | "Custom";

export interface CreateFieldRequest {
  name: string;
  type: EntityFieldType;
  configuration?: EntityFieldConfiguration;
}

export interface AddFieldToObjectClassDefinitionResponse {
  objectClassDefinition: ObjectClassDefinition;
  field: Field;
}

export interface UpdateFieldRequest {
  name: string;
  configuration?: EntityFieldConfiguration;
}

export interface Field {
  id: string;
  type: EntityFieldType;
  name: string;
  source: FieldSource;
  configuration?: EntityFieldConfiguration;
  attributes: FieldAttribute[];
}

export type FieldAttribute = "ObjectIdentifier" | "Required" | "ReadOnly";

export type FieldSource = "BusinessCentral" | "Portal" | "UserDefined";

export interface ObjectLogoResponse {
  logoUrl: string;
}

export interface ValidateUniqueFieldRequest {
  fieldId: string;
  value: unknown;
}

export type EntityFieldType =
  | "Text"
  | "Email"
  | "Phone"
  | "Url"
  | "Number"
  | "Money"
  | "Percent"
  | "Select"
  | "MultiSelect"
  | "UserDefinedOptionsSelect"
  | "UserDefinedOptionsMultiSelect"
  | "Date"
  | "Checkbox"
  | "Lookup";

export type LayoutPage = "Card";

export type FieldConfigurationType =
  | "Text"
  | "Percent"
  | "Number"
  | "Money"
  | "Date"
  | "Select"
  | "UserDefinedOptionsSelect"
  | "Lookup";

type BaseEntityFieldConfiguration = {
  $type: FieldConfigurationType;
};

export type EntityFieldConfiguration =
  | TextEntityFieldConfiguration
  | PercentEntityFieldConfiguration
  | UserDefinedOptionsEntityFieldConfiguration
  | OptionsEntityFieldConfiguration
  | NumberEntityFieldConfiguration
  | DateEntityFieldConfiguration
  | CurrencyEntityFieldConfiguration
  | LookupFieldConfiguration;

export interface TextEntityFieldConfiguration extends BaseEntityFieldConfiguration {
  $type: "Text";
  maxLength?: number;
}

export interface PercentEntityFieldConfiguration extends BaseEntityFieldConfiguration {
  $type: "Percent";
  precision?: number;
}

export interface OptionsEntityFieldConfiguration extends BaseEntityFieldConfiguration {
  $type: "Select";
  fieldType: EntityOptionFieldType;
  allowNewOptions: boolean;
  maxNewOptionLength?: number;
}

export interface NumberEntityFieldConfiguration extends BaseEntityFieldConfiguration {
  $type: "Number";
  precision?: number;
}

export interface DateEntityFieldConfiguration extends BaseEntityFieldConfiguration {
  $type: "Date";
  dateComponents?: DateComponent[];
}

export type DateComponent = "Year" | "Month" | "Day";

export interface CurrencyEntityFieldConfiguration extends BaseEntityFieldConfiguration {
  $type: "Money";
  currencyCode?: string;
  precision?: number;
}

export interface UserDefinedOptionsEntityFieldConfiguration extends BaseEntityFieldConfiguration {
  $type: "UserDefinedOptionsSelect";
  allowNewOptions: boolean;
  userDefinedOptions: SelectOption[];
}

export interface SelectOption {
  value: string;
  label: string;
}

export interface MultiLabelSelectOption extends SelectOption {
  secondaryLabel?: string;
}

export interface LookupFieldConfiguration extends BaseEntityFieldConfiguration {
  $type: "Lookup";
  objectType: string;
}

export interface ObjectContactInfo {
  id: string;
  name: string;
  email: string;
}

export interface CreateObjectContactRequest {
  name: string;
  email: string;
}

export interface ObjectContact {
  contactId: string;
  role?: PortalRole;
  categories: string[];
}

export interface UpdateObjectContactsRequest {
  contacts: ObjectContactRequest[];
}

export interface ObjectContactRequest {
  contactId: string;
  role?: PortalRole;
  categories: string[];
}

export interface ObjectInternalUserPermissions {
  userId: string;
  categories: string[];
}

export interface UpdateObjectInternalUserPermissionsRequest {
  userPermissions: ObjectInternalUserPermissionsRequest[];
}

export interface ObjectInternalUserPermissionsRequest {
  userId: string;
  categories: string[];
}

export interface ObjectInternalUser {
  id: string;
  name: string;
  email: string;
}

export interface ObjectAccessCategory {
  id: string;
  name: string;
  type: string;
}
