import { Box, Button, Stack } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useCallback, useMemo, useState } from "react";
import { createApiResponse, withErrorHandling } from "../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../shared/hooks/useFetch";
import { logError } from "../../../shared/logging";
import cloneDeep from "../../../shared/utilities/cloneDeep";
import { invalidResult, ValidationResult, validResult } from "../../../shared/utilities/validators";
import adminApi from "../../api/adminApi";
import { FieldIdToLookupReferences, FieldIdToValueMap, ObjectClassDefinition } from "../../api/types/objectTypes";
import usePageLeaveConfirmation from "../../hooks/usePageLeaveConfirmation";
import EntitySectionHeader from "./EntitySectionHeader";
import FieldList from "./FieldList";
import { FieldValuesContextProvider, FieldValuesState } from "./FieldValuesContext";
import { getVisibleFieldGroupModels, isAnyRequiredFieldEmpty, updateFieldValues } from "./fieldValuesManagement";

interface Props {
  objectDefinition: ObjectClassDefinition;
  fieldValues: FieldIdToValueMap;
  lookupObjects?: FieldIdToLookupReferences;
  hasPermissionsToManageFields: boolean;
  saveFieldValues: (fieldValues: FieldIdToValueMap) => Promise<boolean>;
}

const validateUniqueField = withErrorHandling(adminApi.validateUniqueField);

const FieldValuesManager = ({
  objectDefinition,
  fieldValues,
  lookupObjects,
  hasPermissionsToManageFields,
  saveFieldValues,
}: Props) => {
  const [isSaving, setSaving] = useState(false);

  const [originalFieldValues, setOriginalFieldValues] = useState<FieldIdToValueMap>(fieldValues);

  const [fieldValuesState, setFieldValuesState] = useState<FieldValuesState>({
    fieldValues,
    isValid: true,
    fieldEditState: "viewing",
  });

  const { setShouldPrompt } = usePageLeaveConfirmation(false);

  const getCardLayout = useCallback(
    () => adminApi.getCardLayout(objectDefinition.objectType),
    [objectDefinition.objectType]
  );

  const [cardLayout, cardLayoutFetchError, { isFetching: isFetchingLayout }] = useFetch(getCardLayout);

  const getLookupOptions = useCallback(
    () =>
      hasPermissionsToManageFields
        ? adminApi.getLookupOptions(objectDefinition.objectType)
        : Promise.resolve(createApiResponse({})),
    [hasPermissionsToManageFields, objectDefinition.objectType]
  );

  const [lookupOptionsByObjectType, , { isFetching: isFetchingLookupOptions }] = useFetch(getLookupOptions);

  const fieldGroups = useMemo(
    () =>
      cardLayout === undefined
        ? []
        : getVisibleFieldGroupModels(cardLayout.fieldGroups, !hasPermissionsToManageFields, objectDefinition.fields),
    [cardLayout, hasPermissionsToManageFields, objectDefinition.fields]
  );

  const updateFieldValuesState = useCallback(
    (stateUpdate: Partial<FieldValuesState>) => setFieldValuesState((prev) => ({ ...prev, ...stateUpdate })),
    []
  );

  if (cardLayoutFetchError) {
    logError(cardLayoutFetchError, `[FieldValuesManager (${objectDefinition.objectType})]`);
    return <DataLoadingFailed />;
  }

  const handleStateValidation = (validationResult: ValidationResult, fieldValues: FieldIdToValueMap) => {
    if (validationResult.isValid) {
      updateFieldValuesState({ fieldValues, isValid: true, fieldEditState: "viewing" });
    } else {
      updateFieldValuesState({ isValid: false, fieldEditState: "editing" });
    }
  };

  const onFieldValueChange = (value: unknown, fieldId: string, validationResult: ValidationResult) => {
    const changedFieldValue = fieldValuesState.fieldValues[fieldId];

    // If field value is not defined or reset to undefined - no need to update
    if (!changedFieldValue && !value) {
      handleStateValidation(validationResult, fieldValuesState.fieldValues);
      return;
    }

    const updatedFieldValues = updateFieldValues(cloneDeep(fieldValuesState.fieldValues), value, fieldId);
    const changesExist = !deepEqual(updatedFieldValues, originalFieldValues);
    setShouldPrompt(changesExist);

    if (fieldValuesState.fieldEditState === "canceling") {
      updateFieldValuesState({ fieldValues: cloneDeep(originalFieldValues), isValid: true, fieldEditState: "viewing" });
      return;
    }
    handleStateValidation(validationResult, updatedFieldValues);
  };

  const saveFieldsChanges = async () => {
    setSaving(true);

    const changedFieldValues = Object.entries(fieldValuesState.fieldValues).reduce<FieldIdToValueMap>(
      (result, [fieldId, value]) => {
        if (
          !deepEqual(value, originalFieldValues[fieldId]) &&
          objectDefinition.fields.some((f) => f.id === fieldId && !f.attributes.includes("ReadOnly"))
        ) {
          result[fieldId] = value;
        }
        return result;
      },
      {}
    );

    const isSaved = await saveFieldValues(changedFieldValues);
    setShouldPrompt(!isSaved);
    setSaving(false);
    if (isSaved) {
      setOriginalFieldValues(fieldValuesState.fieldValues);
    }
  };

  const cancelFieldsChanges = () => {
    if (fieldValuesState.fieldEditState === "editing") {
      updateFieldValuesState({ isValid: true, fieldEditState: "canceling" });
    } else {
      updateFieldValuesState({ fieldValues: cloneDeep(originalFieldValues), isValid: true, fieldEditState: "viewing" });
    }
    setShouldPrompt(false);
  };

  const validateUniqueFieldValue = async (fieldId: string, value: unknown): Promise<ValidationResult> => {
    const isUniqueValidationSupported = objectDefinition.businessCentralType !== "Entity";
    if (!isUniqueValidationSupported) {
      return validResult();
    }

    const storedFieldValue = fieldValuesState.fieldValues[fieldId];
    if (storedFieldValue === value) {
      return validResult();
    }

    const [resp, error] = await validateUniqueField(objectDefinition.objectType, { fieldId, value });
    if (error) {
      logError(error, `[validateUniqueField] ${objectDefinition.objectType} - ${fieldId}`);
      return validResult();
    }

    return resp.result ? validResult() : invalidResult("Duplicate value in a unique field");
  };

  const areFieldsChanged = !deepEqual(fieldValuesState.fieldValues, originalFieldValues);

  // Need to check this additionally since validation state is not updated until the field is touched
  const areThereEmptyRequiredFields = isAnyRequiredFieldEmpty(objectDefinition.fields, fieldValuesState.fieldValues);

  const disableButtons = fieldValuesState.fieldEditState === "editing" || isSaving || !areFieldsChanged;

  const disableSaveButton = disableButtons || !fieldValuesState.isValid || areThereEmptyRequiredFields;

  const isLoading = isFetchingLayout || isFetchingLookupOptions || isSaving;

  return (
    <FieldValuesContextProvider
      objectType={objectDefinition.objectType}
      fieldValuesState={fieldValuesState}
      updateFieldValuesState={updateFieldValuesState}
      fieldGroups={fieldGroups}
      lookupObjects={lookupObjects ?? {}}
      lookupOptionsByObjectType={lookupOptionsByObjectType ?? {}}
      onFieldValueChange={onFieldValueChange}
      validateUniqueFieldValue={validateUniqueFieldValue}
    >
      <Stack spacing={2} width="100%" height="100%">
        <EntitySectionHeader title="Details">
          {hasPermissionsToManageFields && (
            <Stack spacing={1} direction="row">
              <Button disabled={disableButtons} onClick={cancelFieldsChanges} variant="text" color="secondary">
                Cancel
              </Button>
              <Button disabled={disableSaveButton} onClick={saveFieldsChanges} variant="contained" color="primary">
                Save Changes
              </Button>
            </Stack>
          )}
        </EntitySectionHeader>

        <Box flex={1} overflow="auto" px={2.5} pb={2}>
          <FieldList />
        </Box>

        {isLoading && <InlineLoader />}
      </Stack>
    </FieldValuesContextProvider>
  );
};

export default FieldValuesManager;
