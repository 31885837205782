import { distinct } from "../../../../shared/utilities/arrayHelper";
import { DescriptiveDateRange, last12Months } from "../../../../shared/utilities/dateFilterHelper";
import { DocumentCollection, DocumentCollectionPublishStatus } from "../../../api/types/documentCollectionTypes";
import storage from "../../../storage/storage";
import { createSearchFilter } from "./searchFilter";

export interface DocumentCollectionsFilterState {
  allItems: DocumentCollection[];
  filteredItems: DocumentCollection[];
  searchTerm: string;
  dateRange: DescriptiveDateRange;
  allFunds: string[];
  selectedFunds: string[];
  selectedStatuses: DocumentCollectionPublishStatus[];
}

type Action =
  | {
      type: "LOAD_DATA";
      items: DocumentCollection[];
    }
  | {
      type: "SEARCH";
      searchTerm: string;
    }
  | {
      type: "FILTER_FUNDS";
      selectedFunds: string[];
    }
  | {
      type: "FILTER_STATUSES";
      selectedStatuses: DocumentCollectionPublishStatus[];
    }
  | {
      type: "SET_DATE_RANGE";
      dateRange: DescriptiveDateRange;
      clientCode: string;
    }
  | {
      type: "CLEAR_FILTERS";
      clientCode: string;
    };

export const statusFilterOptions: { value: DocumentCollectionPublishStatus; label: string }[] = [
  {
    value: "Preview",
    label: "Preview",
  },
  {
    value: "PartiallyLive",
    label: "Live (Partially)",
  },
  {
    value: "Live",
    label: "Live",
  },
];

const applySearch = createSearchFilter<DocumentCollection>(3, (c) => [c.name, c.fundName, ...c.categories]);

const itemMatchesFilters = (item: DocumentCollection, state: DocumentCollectionsFilterState): boolean => {
  const matchesFund =
    state.selectedFunds.length === 0 || (!!item.fundName && state.selectedFunds.includes(item.fundName));
  const matchesStatus = state.selectedStatuses.length === 0 || state.selectedStatuses.includes(item.status);
  return matchesFund && matchesStatus;
};

const applyFilters = (newState: DocumentCollectionsFilterState): DocumentCollectionsFilterState => {
  let filteredItems = newState.allItems;
  filteredItems = filteredItems.filter((item) => itemMatchesFilters(item, newState));
  filteredItems = applySearch(filteredItems, newState.searchTerm);
  return { ...newState, filteredItems };
};

const getAllFunds = (items: DocumentCollection[]) => {
  const fundNames = items.map((item) => item.fundName).filter(Boolean) as string[];
  return distinct(fundNames).sort((a, b) => a.localeCompare(b));
};

export const defaultDateRange = last12Months;

export const getInitialState = (clientCode: string): DocumentCollectionsFilterState => ({
  allItems: [],
  filteredItems: [],
  searchTerm: "",
  allFunds: [],
  selectedFunds: [],
  selectedStatuses: [],
  dateRange: storage.getDocumentCollectionsDateRange(clientCode) ?? defaultDateRange,
});

export const reducer = (state: DocumentCollectionsFilterState, action: Action): DocumentCollectionsFilterState => {
  switch (action.type) {
    case "LOAD_DATA": {
      return applyFilters({
        ...state,
        allItems: action.items,
        allFunds: getAllFunds(action.items),
      });
    }
    case "SEARCH": {
      return applyFilters({ ...state, searchTerm: action.searchTerm });
    }
    case "FILTER_FUNDS": {
      return applyFilters({ ...state, selectedFunds: action.selectedFunds });
    }
    case "FILTER_STATUSES": {
      return applyFilters({ ...state, selectedStatuses: action.selectedStatuses });
    }
    case "SET_DATE_RANGE": {
      storage.saveDocumentCollectionsDateRange(action.clientCode, action.dateRange);
      return { ...state, dateRange: action.dateRange };
    }
    case "CLEAR_FILTERS": {
      storage.saveDocumentCollectionsDateRange(action.clientCode, defaultDateRange);
      return {
        ...state,
        dateRange: defaultDateRange,
        selectedFunds: [],
        selectedStatuses: [],
        filteredItems: applySearch(state.allItems, state.searchTerm),
      };
    }
    default: {
      return state;
    }
  }
};
