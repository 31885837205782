import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../shared/logging";
import { stringComparerBy } from "../../../../shared/utilities/arrayHelper";
import adminApi from "../../../api/adminApi";
import { ObjectAccessCategory, ObjectClassDefinition } from "../../../api/types/objectTypes";
import AccessEditor, { AccessValue } from "../../common/AccessEditor";
import MultiselectAutocomplete from "../../pages/fund-structure/common/MultiselectAutocomplete";
import {
  getCategoryOptions,
  mapObjectInternalUserPermissionsToUserAccess,
  mapUserAccessToObjectInternalUserPermissionsRequest,
  User,
  UserAccess,
} from "./userAccessMatrixModel";

interface Props {
  objectId: string;
  objectDefinition: ObjectClassDefinition;
  categories: ObjectAccessCategory[];
  currentAccessItems: UserAccess[];
  allUsers: User[];
  onSave: (updatedAccessItems: UserAccess[]) => void;
  onClose: () => void;
}

const updateObjectInternalUserPermissions = withErrorHandling(adminApi.updateObjectInternalUserPermissions);

const AddInternalUserAccessDialog = ({
  objectId,
  objectDefinition,
  categories,
  currentAccessItems,
  allUsers,
  onSave,
  onClose,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const [accessValue, setAccessValue] = useState<AccessValue>({
    selectedRole: undefined,
    selectedCategoryIds: categories.map((c) => c.id),
  });

  const userOptions = useMemo(() => {
    const currentIds = currentAccessItems.map((c) => c.id);
    return allUsers.filter((c) => !currentIds.includes(c.id)).sort(stringComparerBy((o) => o.name));
  }, [allUsers, currentAccessItems]);

  const categoryOptions = useMemo(() => getCategoryOptions(categories), [categories]);

  const handleSave = async () => {
    const addedAccessItems: UserAccess[] = selectedUsers.map((c) => ({
      id: c.id,
      role: accessValue.selectedRole === "None" ? undefined : accessValue.selectedRole,
      categories: accessValue.selectedCategoryIds,
    }));

    const payload = {
      userPermissions: [...currentAccessItems, ...addedAccessItems].map(
        mapUserAccessToObjectInternalUserPermissionsRequest
      ),
    };

    setSaving(true);
    const [updatedUsers, error] = await updateObjectInternalUserPermissions(
      objectDefinition.objectType,
      objectId,
      payload
    );
    setSaving(false);

    if (error) {
      logError(error, "[AddInternalUserAccessDialog] updateObjectInternalUserPermissions");
      sendNotificationError("Could not add user(s)");
      return;
    }

    sendNotification("User(s) successfully added to this company");
    onSave(updatedUsers.map(mapObjectInternalUserPermissionsToUserAccess));
    onClose();
  };

  const isValid = selectedUsers.length > 0;

  return (
    <>
      <Dialog open onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Add New</DialogTitle>
        <DialogCloseButton onClick={onClose} />
        <Divider />

        <DialogContent>
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography color="text.secondary">Select one or more existing internal users.</Typography>
              <MultiselectAutocomplete
                options={userOptions}
                values={selectedUsers}
                onSelected={(items) => setSelectedUsers(items)}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                getOptionDescription={(option) => option.email}
                placeholder="Internal Users"
                noItemsText="No users found"
              />
            </Stack>

            <AccessEditor
              value={accessValue}
              onChange={(update) => setAccessValue((prev) => ({ ...prev, ...update }))}
              roleOptions={[]}
              categoryOptions={categoryOptions}
            />
          </Stack>
        </DialogContent>

        <Divider />
        <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
          <Button variant="text" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton variant="contained" loading={isSaving} onClick={handleSave} disabled={!isValid}>
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddInternalUserAccessDialog;
