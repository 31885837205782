import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import SaveIcon from "@mui/icons-material/FileDownloadOutlined";
import PublishIcon from "@mui/icons-material/LanguageRounded";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import ActionsMenuButton from "../../../../../shared/components/ActionsMenuButton";
import { logError } from "../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../shared/services/downloadFile";
import { convertISODate } from "../../../../../shared/utilities/dateUtils";
import adminApi from "../../../../api/adminApi";
import { DocumentCollectionDetails } from "../../../../api/types/documentCollectionTypes";
import { useClientContext } from "../../../../context/ClientContext";
import UnpublishIcon from "../../../../icons/LanguageOffIcon";
import { pageRoutes } from "../../../../routes";
import DocumentCollectionStatusTag from "../DocumentCollectionStatusTag";
import DeliveryProgress from "./DeliveryProgress";
import { useDocumentCollectionContext } from "./DocumentCollectionContext";

interface Props {
  documentCollectionDetails: DocumentCollectionDetails;
}

const getDownloadUrlForDocumentCollection = withErrorHandling(adminApi.getDownloadUrlForDocumentCollection);

const DocumentCollectionHeader = ({ documentCollectionDetails }: Props) => {
  const navigate = useNavigate();
  const { clientCode } = useClientContext();
  const { hasPermissionsToManage, onChangeDocumentCollectionStatus } = useDocumentCollectionContext();

  const handleBackClick = () => {
    navigate(`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.documentCollections}`, {
      state: { documentCollectionId: documentCollectionDetails.id },
    });
  };

  const handleDownloadAllClick = async () => {
    const [url, error] = await getDownloadUrlForDocumentCollection(documentCollectionDetails.id, clientCode);
    if (error) {
      logError(error, "[DocumentCollectionHeader] handleDownloadAllClick");
      return;
    }

    downloadFileFromUrl(url);
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box ml={1} display="flex" alignItems="flex-start" gap={2}>
        <IconButton onClick={handleBackClick}>
          <ArrowBackRoundedIcon />
        </IconButton>
        <Stack spacing={1}>
          <Box display="flex" gap={2}>
            <Typography variant="h6">{documentCollectionDetails.name}</Typography>
            <DocumentCollectionStatusTag status={documentCollectionDetails.status} />
          </Box>
          <Box display="flex" gap={2}>
            <Typography color="text.secondary">{documentCollectionDetails.fundName}</Typography>
            <Typography color="text.secondary">{`${documentCollectionDetails.messageLevel} level`}</Typography>
            <Typography color="text.secondary">{convertISODate(documentCollectionDetails.publishDate)}</Typography>
          </Box>
        </Stack>
      </Box>

      <Stack direction="row" alignItems="center" spacing={1}>
        <DeliveryProgress
          documentsCount={documentCollectionDetails.documents.length}
          deliveryProgress={documentCollectionDetails.deliveryProgress}
        />
        <Button color="secondary" variant="outlined" onClick={handleDownloadAllClick} startIcon={<SaveIcon />}>
          All Documents
        </Button>

        {hasPermissionsToManage && documentCollectionDetails.availableOperations.includes("MakeLive") && (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<PublishIcon />}
            onClick={() => onChangeDocumentCollectionStatus("MakeLive")}
          >
            Publish to Live
          </Button>
        )}

        {hasPermissionsToManage && documentCollectionDetails.availableOperations.includes("MakePreview") && (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<UnpublishIcon />}
            onClick={() => onChangeDocumentCollectionStatus("MakePreview")}
          >
            Unpublish to Preview
          </Button>
        )}

        {hasPermissionsToManage && documentCollectionDetails.availableOperations.includes("Revoke") && (
          <ActionsMenuButton
            items={[
              {
                icon: <DeleteIcon color="error" />,
                label: "Delete",
                onClick: () => onChangeDocumentCollectionStatus("Revoke"),
              },
            ]}
          />
        )}
      </Stack>
    </Box>
  );
};

export default DocumentCollectionHeader;
