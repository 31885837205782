import { Grid2 } from "@mui/material";
import { useClientContext } from "../../../../context/ClientContext";
import ApiGatewayIcon from "../../../../icons/ApiGatewayIcon";
import DataBackupIcon from "../../../../icons/DataBackupIcon";
import FundAccountingIcon from "../../../../icons/FundAccountingIcon";
import FundraisingIcon from "../../../../icons/FundraisingIcon";
import InvestorPortalIcon from "../../../../icons/InvestorPortalIcon";
import PortfolioMonitoringAppIcon from "../../../../icons/PortfolioMonitoringAppIcon";
import { pageRoutes } from "../../../../routes";
import IntegrationsCard from "./IntegrationsCard";

const AppsDashboard = () => {
  const {
    clientCode,
    hasAnyPermission,
    businessCentralApp,
    investorPortalApp,
    fundraisingApp,
    portfolioMonitoringApp,
    apiGatewayApp,
    dataBackupApp,
  } = useClientContext();

  const canConfigureInvestorPortal = hasAnyPermission(["ViewInvestorPortalSettings", "ManageInvestorPortalSettings"]);
  const canConfigureFundraisings = hasAnyPermission(["ViewFundraisings", "ManageFundraisings"]);
  const canConfigureApiGateway = hasAnyPermission(["ViewClientIntegrations", "ManageClientIntegrations"]);

  return (
    <Grid2 container spacing={3}>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--fund-operations"
          title="Fund Operations"
          Icon={FundAccountingIcon}
          appStatus={businessCentralApp?.status ?? "NotConnected"}
          text="Powerful dual-sided accounting, with intuitive event-driven automations tailored for private capital and other alternative asset funds."
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--investor-portal"
          title="Investor Portal"
          Icon={InvestorPortalIcon}
          appStatus={investorPortalApp?.status ?? "NotConnected"}
          text="Modern tools for messaging and connectivity with your investors."
          configurationRoute={
            canConfigureInvestorPortal
              ? `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.investorPortalSettings}`
              : undefined
          }
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--fundraising"
          title="Fundraising"
          Icon={FundraisingIcon}
          appStatus={fundraisingApp?.status ?? "NotConnected"}
          text="Secure, customizable data rooms that simplify fundraising, offer analytics, and provide a seamless investor experience."
          configurationRoute={
            canConfigureFundraisings
              ? `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.fundraisings}`
              : undefined
          }
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--portfolio-monitoring"
          title="Portfolio Monitoring"
          Icon={PortfolioMonitoringAppIcon}
          appStatus={portfolioMonitoringApp?.status ?? "NotConnected"}
          text="Streamline data collection and track metrics to gain insights into the performance of your portfolio companies."
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--api-gateway"
          title="API Gateway"
          Icon={ApiGatewayIcon}
          appStatus={apiGatewayApp?.status ?? "NotConnected"}
          text="Enabling a 360 degree view of your data throughout your firm, while eliminating manual, high-risk data transfer methods."
          configurationRoute={
            canConfigureApiGateway ? `/${clientCode}/${pageRoutes.integration}/${pageRoutes.apiGateway}` : undefined
          }
        />
      </Grid2>
      <Grid2>
        <IntegrationsCard
          cardId="integrations-card--data-backup"
          title="Data Backup"
          Icon={DataBackupIcon}
          appStatus={dataBackupApp?.status ?? "NotConnected"}
          text="Configure daily backups of your financial data to Amazon S3 for added risk mitigation and regulatory compliance."
          configurationRoute={
            canConfigureApiGateway ? `/${clientCode}/${pageRoutes.integration}/${pageRoutes.dataBackup}` : undefined
          }
        />
      </Grid2>
    </Grid2>
  );
};

export default AppsDashboard;
