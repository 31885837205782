import DeleteIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Container } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ActionsMenuButton from "../../../../../shared/components/ActionsMenuButton";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../api/adminApi";
import { UserConsentDetails } from "../../../../api/types/userConsentTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import DeleteUserConsentDialog from "./DeleteUserConsentDialog";
import UserConsentDetailsPageTitle from "./UserConsentDetailsPageTitle";
import UserConsentEditor from "./UserConsentEditor";
import UserConsentPreviewDialog from "./UserConsentPreviewDialog";

interface DialogState {
  openDialog?: "preview" | "delete";
  consentNameToDelete?: string;
}

const UserConsentDetailsPage = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const { clientCode, clientTitle, hasPermissions } = useClientContext();

  const [dialogState, setDialogState] = useState<DialogState>({});

  const getUserConsentDetails = useCallback(() => adminApi.getUserConsentDetails(defined(name)), [name]);
  const [consentDetails, fetchConsentDetailsError, { setData: setConsentDetails }] = useFetch(getUserConsentDetails);

  const [portalSettingsResp, fetchPortalSettingsError] = useFetch(adminApi.getInvestorPortalSettings);
  const [categories, fetchCategoriesError] = useFetch(adminApi.getAccessCategories);

  const fetchError = fetchConsentDetailsError || fetchPortalSettingsError || fetchCategoriesError;
  if (fetchError) {
    logError(fetchError, "[UserConsentDetailsPage] fetchError");
    return <DataLoadingFailed />;
  }

  const loading = !consentDetails || !portalSettingsResp || !categories;
  if (loading) {
    return <InlineLoader />;
  }

  const hasEditPermissions = hasPermissions(["ManageInvestorPortalSettings"]);

  const userConsentsListPath = `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.investorPortalSettings}?tab=user_consents`;

  const handleConsentUpdated = (updatedConsentDetails: UserConsentDetails) => {
    setConsentDetails(updatedConsentDetails);
  };

  const handleDeleteConsent = () => {
    setDialogState({ openDialog: "delete", consentNameToDelete: consentDetails.name });
  };

  const handleConsentDeleted = () => {
    setDialogState({});
    navigate(userConsentsListPath);
  };

  const handleShowPreview = () => {
    setDialogState({ openDialog: "preview" });
  };

  const isPreviewDisabled = !consentDetails.content;

  return (
    <>
      <GeneralPageHeader
        TitleComponent={<UserConsentDetailsPageTitle consentDetails={consentDetails} categories={categories} />}
        showDefaultBackButtonTo={userConsentsListPath}
      >
        <Button
          disabled={loading || isPreviewDisabled}
          onClick={handleShowPreview}
          variant="outlined"
          startIcon={<VisibilityIcon />}
        >
          Preview
        </Button>
        {hasEditPermissions && (
          <ActionsMenuButton
            items={[
              {
                icon: <DeleteIcon color="error" />,
                label: "Delete",
                onClick: () => handleDeleteConsent(),
              },
            ]}
          />
        )}
      </GeneralPageHeader>

      <Container maxWidth="md" disableGutters sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <UserConsentEditor
          consentDetails={consentDetails}
          onUpdated={handleConsentUpdated}
          readOnly={!hasEditPermissions}
        />
      </Container>

      {dialogState.openDialog === "delete" && dialogState.consentNameToDelete && (
        <DeleteUserConsentDialog
          consentName={dialogState.consentNameToDelete}
          onClose={() => setDialogState({})}
          onDeleted={handleConsentDeleted}
        />
      )}

      <UserConsentPreviewDialog
        open={dialogState.openDialog === "preview"}
        onClose={() => setDialogState({})}
        clientTitle={clientTitle}
        consentName={consentDetails.name}
        consentContent={consentDetails.content ?? ""}
        customAgreementText={consentDetails.customAgreementText}
        logoUrl={portalSettingsResp.settings.contactInvitationEmailCompanyLogoUrl}
      />
    </>
  );
};

export default UserConsentDetailsPage;
